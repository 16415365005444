import { useMsal } from '@azure/msal-react';
import { useState } from 'react';
import { getApi } from './common/api-utils';

export type UserApi = {
	isLoading: boolean,
	hasError: boolean,
	getSingle: ( id: number | string ) =>Promise<any>,
	getPermissionsByRoles: (roles: string[])=>Promise<any>
	getAll: ( config: any ) =>Promise<any>,
	uploadProfileImage: ( file: any ) =>Promise<any>,
}

const url = '/user';
const plural = '/file';
const single = '/file';

// plural and single may be used for message logic if needed in the ApiCore class.

const apiOptions = {
	getAll: true,
	getSingle: true,
	post: true,
	put: false,
	patch: false,
	delete: false,
	url: url,
	plural: plural,
	single: single
};

export const useUserApi = ( errorCallback?: any ): UserApi => {

	const [ isLoading, setIsLoading ] = useState( false );
	const [ hasError, setHasError ] = useState( false );
	const { instance, accounts } = useMsal();

	const handleResponse = ( response: any ) => {
		setIsLoading( false );
		return response.data;
	};

	const handleError = ( response: any ) => {
		setIsLoading( false );
		const isError = response.status >= 400;
		setHasError( isError );
		if ( !isError ) {
			return;
		}
		const data = response.data;
		if ( errorCallback ) {
			return errorCallback( data );
		}
		throw data;
	};

	const uploadProfileImage = async ( file: any ) => {
		setIsLoading( true );
		const formData = new FormData();
		formData.append( 'file', file );

		const url = `${ apiOptions.url }/me/upload`;
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider().post( url, formData, { headers: { 'Content-Type': 'multipart/form-data' } } )
		          .then( handleResponse )
		          .then( response => {
			          return response;
		          } )
		          .catch( handleError );
	};

	const getSingle = async ( id: number | string ) => {
		setIsLoading( true );
		const api = await getApi( instance, accounts, apiOptions );
		return api.getSingle( id )
		          .then( handleResponse )
		          .catch( handleError );
	};



	const getPermissionsByRoles  = async  (roles: string[])=>{
		setIsLoading( true );
		const url = `${ apiOptions.url }/perms`;
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider()
		          .post( url, { roles } )
		          .then( handleResponse )
		          .catch( handleError );
	}

	const getAll = async ( config: any ) => {
		setIsLoading( true );
		const api = await getApi( instance, accounts, apiOptions );
		return api.getAll( config )
		          .then( handleResponse )
		          .catch( handleError );
	};

	return {
		isLoading,
		hasError,
		getSingle,
		getPermissionsByRoles,
		getAll,
		uploadProfileImage
	};
};
