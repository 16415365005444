import { useMsal } from '@azure/msal-react';
import { useState } from 'react';
import { OperatingEntitySchema } from '../components/OperatingEntity/schema';
import { OperatingEntity } from '../components/OperatingEntity/types.js';
import { ApiCoreOptions } from './common/api-core';

import { getApi } from './common/api-utils';

const url = 'operating-entity';

const apiOptions: ApiCoreOptions = {
	getSingle: true,
	getAll: true,
	post: true,
	put: true,
	patch: true,
	delete: true,
	url: url,
};

export const useOperatingEntityApi = ( errorCallback: Function ) => {

	const [ isLoading, setIsLoading ] = useState( false );
	const [ hasError, setHasError ] = useState( false );

	const { instance, accounts } = useMsal();


	const handleResponse = ( response: any ) => {
		setIsLoading( false );
		return response.data;
	};

	const handleError = ( response: any ) => {
		setIsLoading( false );
		const isError = response.status >= 400;
		setHasError( isError );
		if ( !isError ) {
			return;
		}
		const data = response.data;
		if ( errorCallback ) {
			return errorCallback( data );
		}
		throw data;
	};

	const getAll = async () => {
		setIsLoading( true );
		const api = await getApi( instance, accounts, apiOptions );
		return api.getAll()
		          .then( handleResponse )
		          .catch( handleError );
	};

	const getOne = async ( id: number ) => {
		setIsLoading( true );

		const api = await getApi( instance, accounts, apiOptions );
		return api.getSingle( id )
		          .then( handleResponse )
		          .catch( handleError );
	};

	const save = async ( operatingEntity: OperatingEntity ) => {
		const api = await getApi( instance, accounts, apiOptions );
		if ( operatingEntity.id ) {

			return api.put( instance )
			          .then( handleResponse )
			          .then( response => {
				          return response;
			          } )
			          .catch( handleError );
		}

		return api.post( operatingEntity )
		          .then( handleResponse )
		          .then( response => {
			          return response;
		          } )
		          .catch( handleError );
	};

	const remove = async ( id: number ) => {
		const api = await getApi( instance, accounts, apiOptions );
		return api.delete( id )
		          .then( handleResponse )
		          .then( response => {
			          return response;
		          } )
		          .catch( handleError );
	};

	return {
		typeSingle: 'OperatingEntity',
		typePlural: 'OperatingEntities',
		createSchema: OperatingEntitySchema,
		updateSchema: OperatingEntitySchema,
		isLoading,
		hasError,
		getAll,
		getOne,
		save,
		remove
	};
};
