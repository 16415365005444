import React, { useEffect, useState } from 'react';
import { Card, CardContent, IconButton, Typography } from '@mui/material';
import styled from '@emotion/styled';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { ImageUploadDialog } from './ImageUploadDialog';
import { useSnackbar } from 'notistack';
import { Camera } from '@mui/icons-material';
import ProtectedCardMedia from './ProtectedCardMedia';

const Item = styled(Card)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: 0,
	textAlign: 'left',
	verticalAlign: 'top',
	fontSize:'.875rem',
	color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.text.secondary
}));
const MyFab = styled(IconButton)(({ theme }) => ({
	position: 'absolute',
	top: theme.spacing(2),
	right: theme.spacing(2),
	zIndex: 999,
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.text.secondary, // Match typography color
}));
const ImageTile = ({ title = '???????', title2, role = /.*/, data, onSave,
	editable = false,
	readonly = false,
	sx={ height: 320, width: '100%' } }) => {
	const [image, setImage] = React.useState('');
	const [canEdit, setCanEdit] = React.useState(false);
	const [isImageDialogOpen, setImageDialogOpen] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(()=>{
		setImage(data?.images?.at(0));
		setCanEdit(onSave && editable && data?.id && !readonly);
	},[data]);
	return <Item key={title} sx={sx}>
		<CardContent sx={{ padding: '1px', display: 'flex', alignItems: 'center', justifyContent: 'center', verticalAlign: 'center', position: 'relative',
			flex: 1, height: '100%'}} style={{paddingBottom: '0px'}}>
			{ image ? <ProtectedCardMedia image={ image } loading="eager" alt={ title }
				style={ { width: '-webkit-fill-available', height: '-webkit-fill-available' }} /> :
				!readonly ? <Typography>Click pencil to add a photo</Typography> : <Camera sx={{fontSize: 48}}/> }
			{!readonly && <MyFab
				onClick={() => setImageDialogOpen(true)}
				color="primary"
				aria-label="save"
				size="small"
				disabled={ !canEdit }
			>
				<EditIcon/>
			</MyFab> }
		</CardContent>
		{
			<ImageUploadDialog
				isOpen={isImageDialogOpen}
				src={image}
				handleCancel={() => setImageDialogOpen(false)}
				handleSave={async (file, filename)=> {
					const res = await onSave(file, filename);
					if (res?.url) {
						data.images = [res.url];
						setImage(res.url);
						enqueueSnackbar(`Saved ${ filename }`, {
							variant: 'success',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'center'
							}
						});
					}
					setImageDialogOpen(false);
				}}
				title='Project Image'
				aspectRatio={16/9}
			/>
		}
	</Item>;
};
ImageTile.propTypes = {
	title: PropTypes.string,
	title2: PropTypes.string,
	role: PropTypes.object,
	data: PropTypes.object,
	setData: PropTypes.func,
	onSave: PropTypes.func,
	schema: PropTypes.object,
	editProps: PropTypes.object,
	readonly: PropTypes.bool,
	editable: PropTypes.bool,
	sx: PropTypes.object
};

export default ImageTile;
