import { useMsal } from '@azure/msal-react';
import { useState } from 'react';
import { getApi } from './common/api-utils';

const url = 'iam';
const plural = '/iam';
const single = '/iam';

// plural and single may be used for message logic if needed in the ApiCore class.

const apiOptions = {
	getAll: false,
	getSingle: false,
	post: true,
	put: false,
	patch: false,
	delete: false,
	url: url,
	plural: plural,
	single: single
};

export const useIamApi = ( errorCallback: any ) => {

	const [ isLoading, setIsLoading ] = useState( false );
	const [ hasError, setHasError ] = useState( false );
	const { instance, accounts } = useMsal();


	const handleResponse = ( response: any ) => {
		setIsLoading( false );
		return response.data;
	};

	const handleError = ( response: any ) => {
		setIsLoading( false );
		const isError = response.status >= 400;
		setHasError( isError );
		if ( !isError ) {
			return;
		}
		const data = response.data;
		if ( errorCallback ) {
			return errorCallback( data );
		}
		throw data;
	};

	const getRules = async ( config: any ) => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/rules`;
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider().getAll( url, config )
		          .then( handleResponse )
		          .then( response => {
			          return response;
		          } )
		          .catch( handleError );
	};

	const saveRule = async ( payload: { id: number } ) => {
		setIsLoading( true );
		const { id } = payload;
		const url = `${ apiOptions.url }/rules/${ id }`;
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider().post( url, payload )
		          .then( handleResponse )
		          .then( response => {
			          return response;
		          } )
		          .catch( handleError );
	};

	const removeRule = async ( id: number ) => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/rules`;
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider().delete( url, id )
		          .then( handleResponse )
		          .then( response => {
			          return response;
		          } )
		          .catch( handleError );
	};

	return {
		isLoading,
		hasError,
		getRules,
		saveRule,
		removeRule
	};
};
