import { useIsAuthenticated } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import ErrorDisplay from '../../components/ErrorDisplay';

import useWindowSize from '../../components/WindowSize';
import useLocalStorage from 'react-use-localstorage';

import AuditRecordGrid from '../../components/AuditRecord/AuditRecordGrid';
import { useAuditApi } from '../../services/audit';

const AuditRecords = () => {
	const isAuthenticated = useIsAuthenticated();
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(true);
	const handleError = ErrorDisplay();
	const api = useAuditApi(handleError);
	const windowSize = useWindowSize();
	const [view] = useLocalStorage('view','auto');
	const isGrid = view === 'grid' ? true :
		view === 'list' ? false : windowSize.width > 600;
	return (
		<AuditRecordGrid getRows={ api.findAll } data={data} setRefresh={setRefresh} api={api}/>
	);
};
export default AuditRecords;
