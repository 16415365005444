import styled from '@emotion/styled';
import {
	CloudDone,
	Summarize
} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
	Card,
	CardActionArea,
	CardHeader,
	Theme,
	Typography
} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { useIsAuthenticated } from "@azure/msal-react";
import { Property } from 'csstype';
import React, {
	useEffect,
	useState
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ErrorDisplay from '../ErrorDisplay';
import useIntlPlus from '../hooks/IntlPlus';
import { useReportingApi } from '../../services/reporting-ui-api';

type SpaceOptionsStatus = any[]


const StyledCard = styled(Card)(({ theme }) => ({
	// @ts-ignore
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	// @ts-ignore
	...theme.typography.body2,
	padding: 0,
	maxHeight: '300px',
	textAlign: 'left',
	verticalAlign: 'top',
	// @ts-ignore
	color: theme.palette.text.secondary
}));


const CenteredContent = styled(CardContent)(({ theme }) => ({
	padding: 0,
	height:"220px",
	textAlign: 'center',
	verticalAlign: 'center',
	justifyContent:'center',
	display: 'flex',
	flexDirection: 'column',
	// @ts-ignore
	color: theme.palette.text.primary

}));


const BudgetRateStatusCard: React.FC = () => {
	const isAuthenticated = useIsAuthenticated();
	const intl = useIntlPlus();
	const [ report, setReport ] = useState<SpaceOptionsStatus>( [] );
	const reportApi = useReportingApi( ErrorDisplay() );
	const href = `./coreio:budget_rates?groupBy=community`;

	const title = 'Spaces Missing Budget Rates';
	useEffect( () => {

		reportApi.getBudgetRateStatus( 'groupBy=portfolio' )
		         .then( ( result ) => {
			         console.dir( result );
			         setReport( result );

		         } );
	}, [] );

	return (
		<Grid item xs={ 12 } md={ 2 } lg={ 3 } xl={ 4 }>
			<StyledCard title={title!} key={`BoardItemCard-${title}`}>
				<CardHeader
					key={ title }
					title={ title }
					sx={{'& .MuiCardHeader-content': { minHeight: '48px' }, '& .MuiCardHeader-title': { fontSize: '1.10rem'}}}/>
				<CardContent>
				<CardActionArea component={ RouterLink } to={ href }>
					<CenteredContent  sx={{ border: 0 }}>
						<Typography
							variant="h2"
						>
							{ report && report[0]?.count}
						</Typography>
					</CenteredContent>

				</CardActionArea>
				</CardContent>
			</StyledCard>
		</Grid>

	);
};


export { BudgetRateStatusCard };
