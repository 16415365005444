import { IPublicClientApplication } from '@azure/msal-browser';
import { AccountInfo } from '@azure/msal-common/browser';
import { AxiosInstance } from 'axios';
import {
	getTokenRequest,

} from 'utils/authConfig';
import { getAuthenticatedApiClient } from './api-client';
import {
	ApiCore,
	ApiCoreOptions
} from './api-core';

export const reAuth = async ( instance: IPublicClientApplication,
                              accounts: AccountInfo[] ) => {
	return async ()=> {
		let results = await instance.acquireTokenSilent( getTokenRequest( accounts ) );
		return results.accessToken;
	}
}

export const getApi = async ( instance: IPublicClientApplication,
                              accounts: AccountInfo[],
                              options: ApiCoreOptions ): Promise<ApiCore> => {
	return instance.acquireTokenSilent( getTokenRequest( accounts ) )
	               // .catch( ( error ) => {
		           //     return instance
			       //         .acquireTokenPopup( loginRequest )
			       //         .then( ( response ) => {
				   //             return response;
			       //         } )
	               // } )
	               .then( result => result.accessToken)
	               .then( ( token: string ) => getAuthenticatedApiClient( token ) )
	               .then( ( client: AxiosInstance ) => new ApiCore( options, client ) )
		;
};
