import { useMsal } from '@azure/msal-react';
import { CreateProjectSchema, UpdateProjectSchema } from '@corespaces/core-io-common';
import { useState } from 'react';
import { getApi } from './common/api-utils';

const url = 'projects';
const plural = 'projects';
const single = 'project';

// plural and single may be used for message logic if needed in the ApiCore class.

const apiOptions = {
	getAll: true,
	getSingle: true,
	post: true,
	put: true,
	patch: true,
	delete: true,
	url: url,
	plural: plural,
	single: single,
};

export const useProjectApi = (errorCallback) => {

	const [isLoading, setIsLoading] = useState(false);
	const [hasError, setHasError] = useState(false);

	const {instance, accounts} = useMsal();

	const handleResponse = response => {
		setIsLoading(false);
		//return response.data || initialData;
		return response.data;
	};

	const handleError = response => {
		setIsLoading(false);
		const isError = response.status >= 400;
		setHasError(isError);
		if (!isError) {
			return;
		}

		// if api error, use that, otherwise, get response message
		let data;
		if (typeof response.data === 'object') {
			data = response.data;
		}
		else {
			data = {
				code: response.status,
				message: response.statusText,
			};
		}
		if (typeof errorCallback === 'function') {
			return errorCallback(data);
		}
		throw data;
	};

	const getProjects = async (config) => {
		setIsLoading(true);
		const api = await getApi( instance, accounts, apiOptions );
		return api.getAll(config)
			.then(handleResponse)
			.catch(handleError);
	};

	const getProject = async (id, subcode) => {
		setIsLoading(true);
		const _id = subcode ? `${ id }/${ subcode }` : id;
		const api = await getApi( instance, accounts, apiOptions );
		return api.getSingle(_id)
			.then(handleResponse)
			.catch(handleError);
	};

	const saveProject = async (project, config) => {
		if (project.id) {
			const api = await getApi( instance, accounts, apiOptions );
			return api.put(project, config)
				.then(handleResponse)
				.then(response => {
					return response;
				})
				.catch(handleError);
		}
		const api = await getApi( instance, accounts, apiOptions );
		return api.post(project, config)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};

	const deleteProject = async (id, config) => {
		const api = await getApi( instance, accounts, apiOptions );
		return api.delete(id, config)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};

	const copyProject = async (id, config) => {
		const api = await getApi( instance, accounts, apiOptions );
		return api.apiProvider.put(`${ url }/${ id }/copy`, config)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};

	const addContact = async (project, contact) => {
		const patch = {
			project: {id: project.id},
			patch: {op: 'add', path: 'contacts', value: contact},
		};
		const api = await getApi( instance, accounts, apiOptions );
		return api.patch(patch)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};

	const removeContact = async (project, contact) => {
		const patch = {
			project: {id: project.id},
			patch: {op: 'remove', path: 'contacts', value: contact},
		};
		const api = await getApi( instance, accounts, apiOptions );
		return api.patch(patch)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};

	const updateVendors = async (project, type, vendor) => {
		const patch = {
			project,
			patch: {op: 'update', path: `vendors/${ type }`, value: vendor},
		};
		const api = await getApi( instance, accounts, apiOptions );
		return api.patch(patch)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};

	const uploadFile = async (project, file, filename) => {
		setIsLoading(true);
		const formData = new FormData();
		formData.append('filename', filename ?? file.id);
		formData.append('file', file);

		const url = `${ apiOptions.url }/${ project.code }/${ project.subCode }/upload`;
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider()
			.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}})
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};

	const getMilestones = async (project) => {
		setIsLoading(true);
		const url = `${ apiOptions.url }/${ project.id ?? project }/milestones/`;
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider()
			.getAll(url)
			.then(handleResponse)
			// .then( response => {
			// 	return response;
			// } )
			.catch(handleError);
	};

	const saveMilestone = async (project, id, payload, config) => {
		setIsLoading(true);
		if (id) {
			const url = `${ apiOptions.url }/${ project.id }/milestones/${ id }`;
			const api = await getApi( instance, accounts, apiOptions );
			return api.getProvider()
				.patch(url, payload, config)
				.then(handleResponse)
				.then(response => {
					return response;
				})
				.catch(handleError);
		}
		else {
			const url = `${ apiOptions.url }/${ project.id }/milestones`;
			const api = await getApi( instance, accounts, apiOptions );
			return api.getProvider()
				.post(url, payload, config)
				.then(handleResponse)
				.then(response => {
					return response;
				})
				.catch(handleError);
		}
	};
	const removeMilestone = async (project, id, config) => {
		setIsLoading(true);
		const url = `${ apiOptions.url }/${ project.id }/milestones`;
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider()
			.delete(url, id, config)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};

	const getTasks = async (project) => {
		setIsLoading(true);
		const url = `${ apiOptions.url }/${ project.id ?? project }/tasks/`;
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider()
			.getAll(url)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};
	const saveTask = async (project, id, payload, config) => {
		setIsLoading(true);
		const url = `${ apiOptions.url }/${ project.id ?? project }/tasks/${ id }`;
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider()
			.post(url, payload, config)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};
	const removeTask = async (project, id, config) => {
		setIsLoading(true);
		const url = `${ apiOptions.url }/${ project.id }/tasks`;
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider()
			.delete(url, id, config)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};
	return {
		isLoading,
		hasError,
		getProjects,
		getProject,
		saveProject,
		deleteProject,
		copyProject,
		addContact,
		removeContact,
		updateVendors,
		uploadFile,
		getMilestones,
		saveMilestone,
		removeMilestone,
		getTasks,
		saveTask,
		removeTask,
		generic: {
			typeSingle: 'Project',
			typePlural: 'Projects',
			createSchema: CreateProjectSchema,
			updateSchema: UpdateProjectSchema,
			isLoading,
			hasError,
			getAll: getProjects,
			getOne: getProject,
			save: saveProject,
			remove: deleteProject,
			copy: copyProject,
			upload: uploadFile,
		},
	};
};
