import Axios from 'axios';



const API_BASE_URL = '/api';
/**
 * Axios wrapper with retry and basic configuration for API Access.
 */
export const getAuthenticatedApiClient = ( token: string) => {
	const config = { baseURL: API_BASE_URL };
	const client = Axios.create( config );

	client.interceptors.request.use( ( config ) => {
		// console.debug('request interceptor', { client, config, token })

		config.headers['Authorization'] = `Bearer ${ token }`
		return config;
	}, ( error ) => Promise.reject( error ), {synchronous: true} );
	client.interceptors.response.use( ( response ) => {
		// console.debug('response interceptor', {client, response})
		return response;
	}, async function ( error ) {
		if ([401].includes(error.response.status)) {
			console.error('401 Unauthorized', {error})
		}
		return Promise.reject( error );
	} );
	return client;
};
