import { useIsAuthenticated } from '@azure/msal-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ListPage from '../../containers/Page/ListPage';
import {
	IconButton,
	ListItem
} from '@mui/material';
import { useIntl } from 'react-intl';
import PageTitle from '../hooks/PageTitle';
import ProjectGridDetailPanelGlobal from './ProjectGridDetailPanelGlobal';

import ErrorDisplay from '../ErrorDisplay';
import { useProjectApi } from '../../services/project-api';
import { useSearchParams } from 'react-router-dom';
import { Print, PrintDisabled, Refresh } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import styled from '@emotion/styled';
import './print-styles.css';
import { useSnackbar } from 'notistack';
import APISelect from '../APISelect';
import Select from '../Select';
import { Groups, ProjectTypes, States } from '@corespaces/core-io-common';
import { convertSearchParamsToObject } from '../../utils';
import { RiskLevels } from './ProjectTypes';

const fields = [
	{
		name: 'code',
		label: 'Code',
	},
	{
		name: 'name',
		label: 'Name'
	},
	{
		name: 'riskLevel',
		label: 'Risk',
		type: 'text',
		operators: [
			{ value: '=', label: '=' },
			{ value: '!=', label: '!=' },
		],
		render:({ value = '' }, onChange) => {
			return (
				<div
					style={{
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						padding: 1,
					}}
				>
					<Select
						id="groupCode"
						variant='outlined'
						options={ RiskLevels }
						onChange={(e, value) => {
							onChange({ value });
						}}
						value={value}
					/>
				</div>
			);
		},
	},
	{
		name: 'groupCode',
		label: 'Group',
		type:'text',
		defaultOperator: '=',
		operators: [
			{ value: '=', label: '=' },
			{ value: '!=', label: '!=' },
		],
		render:({ value = '' }, onChange) => {
			return (
				<div
					style={{
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						padding: 1,
					}}
				>
					<Select
						id="groupCode"
						variant='outlined'
						options={ Groups }
						onChange={(e, value) => {
							onChange({ value });
						}}
						value={value}
					/>
				</div>
			);
		},
	},
	{
		name: 'projectTypeCode',
		label: 'Project Type',
		type:'text',
		defaultOperator: '=',
		operators: [
			{ value: '=', label: '=' },
			{ value: '!=', label: '!=' },
		],
		//filter:(value, q)=>{},
		render:({ value = '' }, onChange) => {
			return (
				<div
					style={{
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						padding: 1,
					}}
				>
					<Select
						id="groupCode"
						variant='outlined'
						options={ ProjectTypes }
						onChange={(e, value) => {
							onChange({ value });
						}}
						value={value}
					/>
				</div>
			);
		},
	},
	{
		name: 'phaseId',
		label: 'Phase',
		type: 'number',
		defaultOperator: 'isOneOf',
		operators: [
			{ value: 'isOneOf', label: 'Is One Of' },
			{ value: '=', label: '=' },
			{ value: '!=', label: '!=' },
			//{ value: '>', label: '>' }
		],
		filter: (value, query)=>{
			switch (query.operator) {
			case 'isOneOf': return Array.isArray(query?.value) ? query?.value.includes(value) : value === query?.value;
			case '=' : return value === query?.value;
			case '!=' : return value !== query?.value;
			//TODO: requires Phase.index but value is id
			// case '>' : return value !== query?.value;
			default : return false;
			}
		},
		render:(e, onChange) => {
			let { operator, value = '' } = e;
			const multiple = operator === 'isOneOf';
			if(multiple) {
				value = Array.isArray(value) ? value : [value];
			}
			else if (Array.isArray(value)) {
				[value] = value;
			}
			return (
				<div
					style={{
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						padding: 1,
					}}
				>
					<APISelect
						sx={{width: '100%'}}
						variant='outlined'
						fullWidth={true}
						multiple={multiple}
						url='/phase/project' loadAllOptions={ true }
						required
						isOptionEqualToValue={ (option, value) =>{
							return option?.id === value;
						} }
						onChange={(event) => {
							const { target: { value } } = event;
							onChange({ value });
						}}
						value={value}
					/>
				</div>
			);
		},
	},
	{
		name: 'stateCode',
		label: 'State',
		type:'text',
		defaultOperator: '=',
		operators: [
			{ value: '=', label: '=' },
			{ value: '!=', label: '!=' },
		],
		//filter:(value, q)=>{},
		render:({ value = '' }, onChange) => {
			return (
				<div
					style={{
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						padding: 16,
					}}
				>
					<Select
						id="groupCode"
						variant='outlined'
						options={ States }
						onChange={(e, value) => {
							onChange({ value });
						}}
						value={value}
					/>
				</div>
			);
		},
	},
	{
		name: 'deliveryYear',
		label: 'Delivery Year',
		type: 'number'
	},
];

const StyledListItem = styled(ListItem)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.text.secondary,
}));

const CoreGlobalProjectList = ({ projects }) => {
	PageTitle({ id: 'project-global', defaultTitle: 'Core Global' });
	const intl = useIntl();
	const isAuthenticated = useIsAuthenticated();
	const [data, setData] = useState(projects);
	const [refresh, setRefresh] = useState(false);
	const [loading, setLoading] = useState(false);
	const handleError = ErrorDisplay();
	const api = useProjectApi(handleError);
	const [searchParams] = useSearchParams();
	const { enqueueSnackbar } = useSnackbar();
	const fetchData = useMemo(()=>(config)=>{
		setLoading(true);
		setRefresh(false);
		const params = convertSearchParamsToObject(searchParams);
		api.getProjects({ params, ...config } )
			.then((data) => {
				setData(data || []);
				setLoading(false);
			});
	},[api, searchParams]);
	const [printEnabled, setPrintEnabled] = useState(false);

	useEffect(()=>{
		if (isAuthenticated) {
			fetchData();
		}
	}, [isAuthenticated]);

	useEffect(() => {
		if (refresh) {
			fetchData({ headers: { 'cache-control':'max-age=0' } }); //force cache refresh
		}
	}, [refresh, api]);
	const componentRef = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		//		onBeforeGetContent: ()=>setPrintEnabled(true),
		//		onAfterPrint: ()=>setPrintEnabled(false),
		onPrintError: (errorLocation, error)=>{
			enqueueSnackbar('An error occurred updating filters.  Please clear or update value and retry.', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center'
				}});
		}
	});

	const Row = ({ index, style, data, isPrint=false }) => {
		const { code } = data || {};
		return (
			<div key={ `${ code }_${ index }` } style={ { ...style, backgroundColor:'grey'}} >
				<StyledListItem alignItems="flex-start" className='page-break'>
					<ProjectGridDetailPanelGlobal row={data} readonly={true} sx={ { height: 770, overflow: 'hidden' }} isPrint={isPrint}/>
				</StyledListItem>
			</div>
		);
	};

	return (<ListPage
		ref={componentRef}
		name="projects_list"
		list={data}
		isLoading={loading}
		fields={fields}
		disablePrint={!printEnabled}
		Row={Row}
		trailing={ <>
			<IconButton
				color='inherit'
				onClick={ () => {
					setRefresh(true);
				} }
				title={ intl.formatMessage({ id: 'refresh', defaultMessage: 'Refresh Data' }) }
			>
				<Refresh/>
			</IconButton>
			{
				printEnabled ? <IconButton color="inherit" onClick={handlePrint} ><Print/></IconButton>
					: <IconButton color="inherit" onClick={()=>setPrintEnabled(true)} title='Click to enable print'><PrintDisabled/></IconButton>
			}
		</>
		}
		listProps={{ itemSize: 780 }}
		searchFieldProps={ {placeholder: 'Search Projects'}}
		getPageProps={(data) => {
			return {
				pageTitle: intl.formatMessage(
					{
						id: 'projects_list',
						defaultMessage: 'Projects {count} rows',
					},
					{ count: data.length }
				),
			};
		}}
	>
	</ListPage>
	);
};
CoreGlobalProjectList.displayName = 'CoreGlobalProjectList';
export default CoreGlobalProjectList;
