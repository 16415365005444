// @ts-ignore

import { useIsAuthenticated } from '@azure/msal-react';
import React, {
	useEffect,
	useState
} from 'react';
import useLocalStorage from 'react-use-localstorage';
import ErrorDisplay from '../../components/ErrorDisplay';
import PartnerGrid from '../../components/Vendor/PartnerGrid';
import {
	Vendor,
	VendorByType
} from '../../components/Vendor/types';

import { useVendorApi } from '../../services/vendor-api';

const Partners = () => {
	const isAuthenticated = useIsAuthenticated();
	const [ data, setData ] = useState<Vendor[]>( [] );
	const [ refresh, setRefresh ] = useState( true );
	const handleError = ErrorDisplay();
	const vendorApi = useVendorApi( handleError );

	useEffect( () => {
		if ( isAuthenticated ) {
			setRefresh( true );
		}
	}, [ isAuthenticated ] );
	useEffect( () => {
		if ( refresh ) {
			setRefresh( false );
			vendorApi.getVendorsByType( 'EQUITY_PARTNER' )
			         .then( ( data: VendorByType ) => {
				         setData( data['EQUITY_PARTNER'] || [] );
			         } );
		}
	}, [ isAuthenticated, refresh, vendorApi ] );
	return (
		<PartnerGrid data={ data } setRefresh={ setRefresh } api={ vendorApi }/>
	);
};
export default Partners;
