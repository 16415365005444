import * as React from 'react';
import { EditDataDialog } from '../EditDataDialog';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import InputField from '../InputField';
import { useIsAuthenticated } from "@azure/msal-react";
import {
	ChangeEvent,
	useState
} from 'react';
import { hasRole } from '../AuthUtils';
//@ts-ignore
import { UserRoles } from '@corespaces/core-io-common';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../AbilityContext';
import { UIGridView } from './types';

export type GridViewDefinitionDialogProps ={
	id: string,
	open: boolean,
	onClose: (...args: any[])=>any,
	onSave: (...args: any[])=>any,
	view: UIGridView<any>,
	categories: string[]
}

const GridViewDefinitionDialog = ({ id, open, onClose, onSave, view, categories=[] }: GridViewDefinitionDialogProps ) =>
{
	const intl = useIntl();
	const isAuthenticated = useIsAuthenticated();
	const ability = useAbility(AbilityContext);
	const [isAdmin] = useState(ability.can('update', 'UIGridView'));
	const [isManager] = useState(isAdmin);
	//TODO: these aren't utilized... yet but want to link behavior
	const [nameValue, setNameValue] = useState(view?.name);
	const [slugValue, setSlugValue] = useState(view?.slug);

	const handleNameChange = (event:  ChangeEvent<HTMLInputElement> ) => {
		const value = event.target.value;
		setNameValue(value);
		const slugValue = value.toLowerCase().replaceAll(' ', '_');
		setSlugValue(slugValue);
	};
	return (
		<EditDataDialog
			open={ open }
			data={ view }
			onClose={ onClose }
			onSave={ onSave }
			title='Save View'
			method='PUT'
			showSuccessMessage={false}
		>
			<InputField
				id="name"
				label={ intl.formatMessage({id:'view_name',defaultMessage: 'View Name' })}
				type="string"
				description={ `Unique name within ${ !isAdmin ? view?.category : 'category'}` }
				required
				value={nameValue}
				onChange={handleNameChange}
			/>
			{ isManager ?
				<>
					<InputField
						id="category"
						label="Category"
						type="autocomplete"
						defaultValue='My View'
						description='Used to group views within the select list'
						options={ categories }
						freeSolo
						disableClearable
						required
						readOnly={!isManager}
						getOptionLabel={(option: any)=>
						{
							return option?.label ? option.label : option;
						}}
					/>
					<InputField
						id='roles.read'
						label='Viewers'
						description='Who can see this view'
						type='select'
						options={[
							{ code: 'none', name: intl.formatMessage({ id: 'none', defaultMessage: 'None' })},
							{ code: 'user', name: intl.formatMessage({ id: 'false', defaultMessage: 'All Users' })},
							{ code: 'admin', name: intl.formatMessage({ id: 'admin', defaultMessage: 'Admin Users' })},
							{ code: 'editor', name: intl.formatMessage({ id: 'editor', defaultMessage: 'COREio Editor' })},
							{ code: 'asset-management-editor', name: intl.formatMessage({ id: 'asset-management-editor', defaultMessage: 'Asset Management Editor' })},
							{ code: 'finance-viewer', name: intl.formatMessage({ id: 'finance-viewer', defaultMessage: 'Finance Viewer' })},
							{ code: 'finance-editor', name: intl.formatMessage({ id: 'finance-editor', defaultMessage: 'Finance Editor' })},
							{ code: 'student-acquisition-editor', name: intl.formatMessage({ id: 'student-acquisition-editor', defaultMessage: 'Student Acquisition Editor' })},
							{ code: 'community-management-editor', name: intl.formatMessage({ id: 'community-management-editor', defaultMessage: 'Community Management Editor' })},
							{ code: 'business-systems-editor', name: intl.formatMessage({ id: 'business-systems-editor', defaultMessage: 'Business Systems Editor' })},
							{ code: 'legal-editor', name: intl.formatMessage({ id: 'legal-editor', defaultMessage: 'Legal Editor' })},
							{ code: 'capital-markets-editor', name: intl.formatMessage({ id: 'capital-markets-editor', defaultMessage: 'Capital Markets Editor' })},
						]}
						readOnly={!isManager}
					/></>
				:null }
			{ isAdmin  ?
				<>
					<InputField
						id="slug"
						disabled={ !!view.system }
						label={ intl.formatMessage({id:'view_slug',defaultMessage: 'View Slug/Id' })}
						type="string"
						description='Unique identifier used to auto-select view from link'
						value={slugValue}
					/>
					<InputField
						id='editable'
						label='Is Edit View'
						type='boolean'
						description='Editors with permission to view will be able to edit data from this view'
						readOnly={!isAdmin}
					/>
					<InputField
						id='system'
						label='Is System View'
						type='boolean'
						description='Flag this view as a system view to prevent deletion.'
						readOnly={!isAdmin}
					/>
					<InputField
						id='roles.update'
						label='Managed By'
						description='Who can update this view'
						type='select'
						options={[
							{ code: 'none', name: intl.formatMessage({ id: 'none', defaultMessage: 'None' })},
							{ code: 'user', name: intl.formatMessage({ id: 'false', defaultMessage: 'All Users' })},
							{ code: 'admin', name: intl.formatMessage({ id: 'admin', defaultMessage: 'Admin Users' })},
							{ code: 'editor', name: intl.formatMessage({ id: 'editor', defaultMessage: 'COREio Editor' })},
							{ code: 'asset-management-editor', name: intl.formatMessage({ id: 'asset-management-editor', defaultMessage: 'Asset Management Editor' })},
							{ code: 'finance-viewer', name: intl.formatMessage({ id: 'finance-viewer', defaultMessage: 'Finance Viewer' })},
							{ code: 'finance-editor', name: intl.formatMessage({ id: 'finance-editor', defaultMessage: 'Finance Editor' })},
							{ code: 'student-acquisition-editor', name: intl.formatMessage({ id: 'student-acquisition-editor', defaultMessage: 'Student Acquisition Editor' })},
							{ code: 'community-management-editor', name: intl.formatMessage({ id: 'community-management-editor', defaultMessage: 'Community Management Editor' })},
							{ code: 'business-systems-editor', name: intl.formatMessage({ id: 'business-systems-editor', defaultMessage: 'Business Systems Editor' })},
							{ code: 'legal-editor', name: intl.formatMessage({ id: 'legal-editor', defaultMessage: 'Legal Editor' })},
							{ code: 'capital-markets-editor', name: intl.formatMessage({ id: 'capital-markets-editor', defaultMessage: 'Capital Markets Editor' })},
						]}
						readOnly={!isAdmin}
					/>
				</>: null }
		</EditDataDialog>);
};
GridViewDefinitionDialog.propTypes = {
	open: PropTypes.any,
	onClose: PropTypes.func,
	onSave: PropTypes.func,
	view: PropTypes.object,
	categories: PropTypes.arrayOf(PropTypes.string)
};
export default GridViewDefinitionDialog;
