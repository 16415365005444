import { useIsAuthenticated } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
// @ts-ignore

import { useSearchParams } from 'react-router-dom';
import PageTitle from '../../components/hooks/PageTitle';
import GlobalSearch from '../../components/GlobalSearch';
// @ts-ignore
import Page from 'material-ui-shell/lib/containers/Page';
import { useReportingApi } from '../../services/reporting-ui-api';
import ErrorDisplay from '../../components/ErrorDisplay';
import Grid from '@mui/material/Grid';
import BoardSection from '../../components/Reports/BoardSection';
import { BoardSectionType } from '../../components/Reports/types';
import { BudgetRateStatusCard } from '../../components/Reports/BudgetRateStatusCard';


const ReportHome = () => {
	const isAuthenticated = useIsAuthenticated();
	const [title] =  useState('Reports');
	const [reports, setReports] = useState<BoardSectionType[]>([]);
	const [searchParams] = useSearchParams();
	const reportApi = useReportingApi(ErrorDisplay());
	PageTitle({ id: 'reports', defaultTitle: 'Reports' });
	useEffect(() => {
		reportApi.getBoard('global')
			.then(r=> {
				setReports(r ?? []);
			});

	}, [isAuthenticated, searchParams]);

	return (
		<Page
			isLoading={reportApi.isLoading}
			pageTitle={`${ title }` }
			appBarContent={
				<>
					<GlobalSearch/>
				</>
			}
		>
			<Grid container width="100%" rowSpacing={ 1 } columnSpacing={ { xs: 1, sm: 1, md: 2 } } padding={ 1 }>
				{reports?.map(r=>
				{ return (
					<BoardSection key={ `BoardSection${ r.title }` } {...r} >
						{ r.title == 'Variance' && <BudgetRateStatusCard></BudgetRateStatusCard>}
					</BoardSection>
				);}
				)}
			</Grid>

		</Page>
	);
};
export default ReportHome;
