import { IPublicClientApplication } from '@azure/msal-browser';
import { AccountInfo } from '@azure/msal-common/browser';

import ApiProvider from '../../services/common/api-provider';
import { getApi } from '../../services/common/api-utils';


/**
 * Hook for ApiProvider
 */
export const getApiProvider = async (instance: IPublicClientApplication, accounts: AccountInfo[]): Promise<ApiProvider> => {

	return getApi(instance, accounts, {url:''}).then((core)=> {return core.getProvider()})
}
