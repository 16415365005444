import ApiProvider from './api-provider';
import { AxiosInstance, AxiosResponse } from 'axios';

export type ApiCoreOptions = {
	getAll?: boolean,
	getSingle?: boolean,
	post?: boolean,
	put?: boolean,
	patch?: boolean,
	delete?: boolean,
	url: string,
	plural?: string,
	single?: string
}
export class ApiCore {
	private apiProvider: ApiProvider;
	public getAll: (( config?:any ) => Promise<AxiosResponse<any> | void>)= ()=> Promise.resolve() ;
	public put: (( model:any, config?: any  ) => Promise<AxiosResponse<any> | void>) = ()=>Promise.resolve();
	public patch: (( model:any, config?: any  ) => Promise<AxiosResponse<any> | void>) = ()=>Promise.resolve();
	public delete: (( id:any, config?: any  ) => Promise<AxiosResponse<any> | void>) = ()=>Promise.resolve();

	public getSingle: (( id:any, config?:any ) => Promise<AxiosResponse<any> | void>)= ()=>Promise.resolve();
	public post: (( model:any, config?: any ) => Promise<AxiosResponse<any> | void>) = ()=> Promise.resolve();
	/**
	 *
	 * @param {{patch: boolean, single: string, getSingle: boolean, getAll: boolean, plural: string, post: boolean, delete: boolean, put: boolean, url: string}} options the base url for this resource
	 * @param client
	 */
	constructor(options:ApiCoreOptions, client:AxiosInstance) {
		this.apiProvider = new ApiProvider(client);
		if (options.getAll) {
			this.getAll = (config) => {
				return this.apiProvider.getAll(options.url, config);
			};
		}

		if (options.getSingle) {
			this.getSingle = (id) => {
				return this.apiProvider.getOne(options.url, id);
			};
		}

		if (options.post) {
			this.post = (model, config?: any) => {
				return this.apiProvider.post(options.url, model, config);
			};
		}

		if (options.put) {
			this.put = (model, config?: any) => {
				return this.apiProvider.put(options.url, model, config);
			};
		}

		if (options.patch) {
			this.patch = (model, config?: any) => {
				return this.apiProvider.patch(options.url, model, config);
			};
		}

		if (options.delete) {
			this.delete = (id, config?: any) => {
				return this.apiProvider.delete(options.url, id, config);
			};
		}
	}

	getProvider(){
		return this.apiProvider;
	}
}
