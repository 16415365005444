import { useIsAuthenticated } from '@azure/msal-react';
import React, { useEffect, useMemo, useState } from 'react';

import ErrorDisplay from '../ErrorDisplay';
import { DataGridPremium, GridColDef, useGridApiRef } from '@mui/x-data-grid-premium';
// @ts-ignore

import { useProjectApi } from '../../services/project-api';
import { Typography } from '@mui/material';
import ContactAvatarGridCell from '../grid/ContactAvatarGridCell';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { ProjectTask, Contact } from './types';

interface ProjectTasksProps {
  project: {
    id: number,
    tasks: ProjectTask[]
  },
  title: string,
  readonly?: boolean,
  limit?: number
}
const ProjectTasks: React.FC<ProjectTasksProps> = ({ readonly = false, project, title, limit=5}) => {
	const isAuthenticated = useIsAuthenticated();
	const apiRef = useGridApiRef();
	const handleError = ErrorDisplay();
	const projectApi = useProjectApi(handleError);
	const [rows, setRows] = useState<ProjectTask[]>([]);

	const columns = useMemo<GridColDef[]>(() => {
		return [
			{
				field: 'name', headerName: 'Task', minWidth: 400, width: 624, description: 'Task Name', editable: false,
				sortable: true
			},
			{
				field: 'contacts', headerName: 'Owners', minWidth: 100, width: 200, description: 'Owner', editable: false,
				sortable: false,
				valueFormatter: (params)=>params.value?.map((c:Contact)=>c.name).join(', '),
				renderCell: (params) =><ContactAvatarGridCell params={params} avatarSx={ { width: 24, height: 24 } }/>,
				// renderEditCell: (params) => (
				// 	<GoogleDirectorySearch { ...params } value={ params.value ?? [] } sx={{minWidth: 300}}
				// 		onChange={ (event, newValue, reason)=> {
				// 			if (newValue && reason === 'selectOption') {
				// 				const newRow = { id: params.row?.id, contacts: newValue };
				// 				apiRef.current.updateRows([newRow]);
				// 			}
				// 		}}
				// 	/>
				// )
			},
			// {
			// 	field: 'updatedAt', headerName: 'Updated', minWidth: 80, type: 'dateTime',
			// 	align: 'right',
			// 	valueFormatter: formatDate,
			// 	valueGetter: getDate
			// },
			// {
			// 	field: 'updatedBy', headerName: 'Updated By', minWidth: 140,
			// 	valueGetter: (v) => v?.value?.name, groupable: false, editable: false
			// },
		];
	}, []);
	const gridState:GridInitialStatePremium= {
		columns: {
			columnVisibilityModel: {
				'contacts': true,
				'name': true,
			},
			dimensions: {},
			orderedFields: ['name', 'contacts']
		},
		sorting: {
			'sortModel': [{ field: 'id', sort: 'asc' }],
		},
	};

	useEffect(() => {
		if (project?.tasks) {
			setRows(limit ? project?.tasks?.slice(0,limit): project?.tasks);
		}
		else {
			project?.id && projectApi.getTasks(project?.id)
				.then((data) => {
					if (Array.isArray(data)) {
						project.tasks = data;
						setRows(data.slice(0,limit));
					}
				});
		}
	}, [isAuthenticated, project?.id]);
	return (
		<div style={ { height: '100%', width: '100%', alignContent: 'space-around', backgroundColor: 'revert' } }>
			<Typography variant='h6' sx={{fontSize: '1.10rem', marginTop: '4px'}}>{title}</Typography>
			<DataGridPremium
				sx={{ fontSize: '0.875rem' }}
				rows={ rows }
				getRowHeight={({ id, densityFactor }) => {
					return 48 * densityFactor;
				}}
				pageSizeOptions={[limit]}
				columns={ columns }
				initialState={gridState}
				//isCellEditable={(params) => readonly && params.colDef.editable }
				apiRef={ apiRef }
				density={'compact'}
				hideFooter
				slots={{
					columnHeaders: () => null,
				}}
			/>
		</div>
	);
};


export default ProjectTasks;
