import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { getApiProvider } from './hooks/API';



const APISelect = (props) => {
	const { url, empty=[] } = props;
	const isAuthenticated = useIsAuthenticated();
	const { instance, accounts} = useMsal();
	const [options, setOptions] = useState([]);
	useEffect(() =>
	{
		getApiProvider(instance, accounts).then(api=> {
			api.getAll(`${ url }`)
				.then(result => {
					setOptions(result?.data ?? empty);
				})
				.catch(error => {
					setOptions(empty);
					console.error(error);
				});
		})
	}, [url, isAuthenticated]);

	return (
		<FormControl margin={props.margin} fullWidth={props.fullWidth} sx={{ minWidth: 120 }}>
			<InputLabel fullWidth={props.fullWidth} variant={props.variant}>{props.label}</InputLabel>
			<Select
				key={props.id}
				{...props}
				fullWidth={props.fullWidth}
				options={options ?? empty}
			>
				{options?.map(option=><MenuItem key={option.id} value={option.id} >{option.name}</MenuItem>)}
			</Select>
			<FormHelperText>{props.helperText}</FormHelperText>
		</FormControl>
	);
};

APISelect.propTypes = {
	id: PropTypes.string,
	url: PropTypes.string.isRequired,
	empty: PropTypes.array,
	label: PropTypes.string,
	fullWidth: PropTypes.bool,
	helperText: PropTypes.string,
	margin: PropTypes.string,
	variant: PropTypes.string,
};

export default APISelect;
