import { useIsAuthenticated } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { useUniversityApi } from '../../services/university-api';
import { UniversityGrid } from '../../components/University/UniversityGrid';
import ErrorDisplay from '../../components/ErrorDisplay';
import useWindowSize from '../../components/WindowSize';
import useLocalStorage from 'react-use-localstorage';


const Universities = () => {
	const isAuthenticated = useIsAuthenticated();
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(true);
	const handleError = ErrorDisplay();
	const universityApi = useUniversityApi(handleError);
	const windowSize = useWindowSize();
	const [view] = useLocalStorage('view','auto');
	// const isGrid = view === 'grid' ? true :
	// 	view === 'list' ? false : windowSize.width > 600;
	useEffect(()=>{
		if (isAuthenticated) {
			setRefresh(true);
		}
	}, [isAuthenticated]);
	useEffect(() => {
		if (refresh) {
			setRefresh(false);
			universityApi.getUniversities()
				.then((data) =>
				{
					setData(data || []);
				});
		}
	}, [refresh, universityApi]);
	return ( <UniversityGrid data={data} setRefresh={setRefresh} api={universityApi}/> );
};
export default Universities;
