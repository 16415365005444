import { useMsal } from '@azure/msal-react';
import { useState } from 'react';
import { UIGridView } from '../components/grid/types';
import { ApiCoreOptions } from './common/api-core';
import { getApi } from './common/api-utils';

const url = 'views';
const plural = '/views';
const single = '/views';

// plural and single may be used for message logic if needed in the ApiCore class.

const apiOptions: ApiCoreOptions = {
	getAll: true,
	getSingle: true,
	post: true,
	put: true,
	patch: false,
	delete: true,
	url: url,
	plural: plural,
	single: single,
};

export const useViewsApi = ( errorCallback: any ) => {

	const [ isLoading, setIsLoading ] = useState( false );
	const [ hasError, setHasError ] = useState( false );
	const { instance, accounts } = useMsal();

	const handleResponse = ( response: any ) => {
		setIsLoading( false );
		return response.data;
	};

	const handleError = ( response: any ) => {
		setIsLoading( false );
		const isError = response.status >= 400;
		setHasError( isError );
		if ( !isError ) {
			return;
		}
		const data = response.data;
		if ( errorCallback ) {
			return errorCallback( data );
		}
		throw data;
	};

	const findAll = async ( type: string, query?: any ) => {
		setIsLoading( true );
		const url = type ? `${ apiOptions.url }/${ type }` : apiOptions.url;
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider()
		          .getAll( url )
		          .then( handleResponse )
		          .catch( handleError );
	};

	const save = async ( view: UIGridView<any> ) => {
		const api = await getApi( instance, accounts, apiOptions );
		if ( view.id ) {
			return api.put( view )
			          .then( handleResponse )
			          .then( response => {
				          return response;
			          } )
			          .catch( handleError );
		}

		return api.post( view )
		          .then( handleResponse )
		          .then( response => {
			          return response;
		          } )
		          .catch( handleError );
	};

	const remove = async ( id: number|string ) => {
		const api = await getApi( instance, accounts, apiOptions );
		return api.delete( id )
		          .then( handleResponse )
		          .then( response => {
			          return response;
		          } )
		          .catch( handleError );
	};

	return {
		isLoading,
		hasError,
		findAll,
		save,
		remove,
	};
};
