import { useIsAuthenticated } from '@azure/msal-react';
import React, {
	createRef,
	useEffect,
	useState
} from 'react';
// @ts-ignore

import {
	useLocation,
	useSearchParams
} from 'react-router-dom';
import Box from '@mui/material/Box';
import { Dashboard } from '../../components/Looker/Dashboard';
import GlobalSearch from '../../components/GlobalSearch';
// @ts-ignore
import Page from 'material-ui-shell/lib/containers/Page';
import { BudgetRateStatusReport } from '../../components/Reports/BudgetRateStatusReport';


const Report = () => {
	const isAuthenticated = useIsAuthenticated();
	const dashboardRef = createRef<HTMLDivElement>();
	const [title, setTitle] =  useState('Reports');
	const [dashboard, setDashboard] = useState<string>('');
	const [loading, setLoading] = useState(false);
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const filters = Object.fromEntries(searchParams);
	//PageTitle({ id: 'reports', defaultTitle: 'Reports: ' + title });
	useEffect(() => {
		const view   = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
		if (view) {
			setLoading(true);
			setDashboard(view);
		}
	}, [isAuthenticated, location]);

	return (
		<Page
			isLoading={loading}
			pageTitle={`${ title }` }
			appBarContent={
				<>
					<GlobalSearch/>
				</>
			}
		>
			<Box sx={ { width: '100%', minHeight: 400, height: '100%', display:'block' } }>
				{ dashboard.startsWith('coreio:')
				  ? <BudgetRateStatusReport isLoading={loading} onLoaded={setLoading}></BudgetRateStatusReport>
				  :
				<Dashboard
					ref={dashboardRef}
					// @ts-ignore
					dashboardId={dashboard}
					params={{
						_theme: '{"show_filters_bar": false, "show_title": false}',
					// embed_browse_spaces: false,
					// show_filters_bar: false,
					}}
					onPageChanged={(dashboard:any)=>{console.dir(dashboard);}}
					onDashboardLoaded={({ dashboard }:any)=> {
						setLoading(false);
						setTitle(dashboard?.title ?? 'Reports');
						document.title = dashboard?.title ? `Reports - ${dashboard.title}` : 'Reports';
					}}
					callback={(dashboard:any)=>{console.dir(dashboard);}}
					filters={ filters }/>
				}
			</Box>
		</Page>
	);
};
export default Report;
