
import { useMsal } from '@azure/msal-react';
// @ts-ignore
import { CreatePropertySchema, UpdatePropertySchema } from '@corespaces/core-io-common';
import { useState } from 'react';
import {
	MSProperty,
	Property,
	PropertyGoal,
	RelativeSchoolYear
} from '../components/Property/types.js';
import { getApi } from './common/api-utils';

const url = 'properties';
const plural = 'properties';
const single = 'property';

// plural and single may be used for message logic if needed in the ApiCore class.

const apiOptions = {
	getAll: true,
	getSingle: true,
	post: true,
	put: true,
	patch: true,
	delete: true,
	url: url,
	plural: plural,
	single: single
};

export const usePropertyApi = ( errorCallback: any ) => {

	const [ isLoading, setIsLoading ] = useState( false );
	const [ hasError, setHasError ] = useState( false );
	const { instance, accounts } = useMsal();

	const handleResponse = ( response: any ) => {
		setIsLoading( false );
		//return response.data || initialData;
		return response?.data;
	};

	const handleError = ( response: any ) => {
		setIsLoading( false );
		const isError = response.status >= 400;
		setHasError( isError );
		if ( !isError ) {
			return;
		}

		// if api error, use that, otherwise, get response message
		let data;
		if ( typeof response.data === 'object' ) {
			data = response.data;
		}
		else {
			data = {
				code: response.status,
				message: response.statusText
			};
		}
		if ( typeof errorCallback === 'function' ) {
			return errorCallback( data );
		}
		throw data;
	};

	const getProperties = async ( config: any ) => {
		setIsLoading( true );

		const api = await getApi( instance, accounts, apiOptions );
		return api.getAll( config )
		          .then( handleResponse )
		          .catch( handleError );
	};

	const getProperty = async ( id: number ) => {
		setIsLoading( true );
		const api = await getApi( instance, accounts, apiOptions );
		return api.getSingle( id )
		          .then( handleResponse )
		          .catch( handleError );
	};

	const saveProperty = async ( property: any, config: any ) => {
		if ( property.id ) {
			const url = `${ apiOptions.url }/${ property.id }`;
			const api = await getApi( instance, accounts, apiOptions );
			return api.getProvider().put( url, property, config )
			          .then( handleResponse )
			          .then( response => {
				          return response;
			          } )
			          .catch( handleError );
		}
		const api = await getApi( instance, accounts, apiOptions );
		return api.post( property, config )
		          .then( handleResponse )
		          .then( response => {
			          return response;
		          } )
		          .catch( handleError );
	};

	const deleteProperty = async ( id: any, config: any ) => {
		const api = await getApi( instance, accounts, apiOptions );
		return api.delete( id, config )
		          .then( handleResponse )
		          .then( response => {
			          return response;
		          } )
		          .catch( handleError );
	};

	const restoreProperty = async ( id: any, config: any ) => {
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider().put( `${ apiOptions.url }/${ id }/restore`, {}, config )
		          .then( handleResponse )
		          .then( response => {
			          return response;
		          } )
		          .catch( handleError );
	};

	const getContacts = async ( propertyId: any ) => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/${ propertyId }/contacts`;
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider()
		          .getAll( url )
		          .then( handleResponse )
		          .catch( handleError );
	};

	const saveContact = async ( propertyId: number, id: number, role: any, contact: any, config: any ) => {
		const model = {
			property: { id: propertyId }, //deficiency of express not providing path param
			role: role,
			contact: contact
		};
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider()
		          .post( `${ apiOptions.url }/${ propertyId }/contacts`, model, config )
		          .then( handleResponse )
		          .then( response => {
			          return response;
		          } )
		          .catch( handleError );
	};

	const removeContact = async ( propertyId: number, id: any, config?: any ) => {
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider()
		          .delete( `${ apiOptions.url }/${ propertyId }/contacts`, id, config )
		          .then( handleResponse )
		          .then( response => {
			          return response;
		          } )
		          .catch( handleError );
	};

	const updateVendors = async ( propertyId: number, type: any, vendor: any ) => {
		const model = {
			property: { id: propertyId },
			type: type,
			vendor: vendor
		};
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider()
		          .post( `${ apiOptions.url }/${ propertyId }/vendors`, model )
		          .then( handleResponse )
		          .then( response => {
			          return response;
		          } )
		          .catch( handleError );
	};

	const removeVendor = async ( propertyId: number, id: any ) => {
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider()
		          .delete( `${ apiOptions.url }/${ propertyId }/vendors`, id )
		          .then( handleResponse )
		          .then( response => {
			          return response;
		          } )
		          .catch( handleError );
	};

	const getProjects = async ( propertyId: any ) => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/${ propertyId }/projects`;
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider()
		          .getAll( url )
		          .then( handleResponse )
		          .catch( handleError );
	};

	const getCompetitors = async ( propertyId: any ) => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/${ propertyId }/competitors`;
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider()
		          .getAll( url )
		          .then( handleResponse )
		          .catch( handleError );
	};

	const saveCompetitor = async ( property: any, competitor: any ) => {
		setIsLoading( true );
		const propertyId = property?.id ?? property;
		const url = `${ apiOptions.url }/${ propertyId }/competitors`;
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider()
		          .post( url, competitor )
		          .then( handleResponse );
	};

	const getPropertyGoals = async ( property: any ): Promise<PropertyGoal[]> => {
		setIsLoading( true );
		const propertyId = property?.id ?? property;
		const url = `${ apiOptions.url }/${ propertyId }/goals`;
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider()
		          .getAll( url )
		          .then( handleResponse );
	};

	const savePropertyGoals = async ( property: Property, goals: Partial<PropertyGoal>[] ): Promise<PropertyGoal[]> => {
		setIsLoading( true );
		const propertyId = property?.id ?? property;
		const url = `${ apiOptions.url }/${ propertyId }/goals`;
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider()
		          .post( url, goals )
		          .then( handleResponse );
	};

	const importPropertyGoals = async ( property: Property, msProperty: MSProperty ): Promise<PropertyGoal[]> => {
		setIsLoading( true );
		const propertyId = property?.id ?? property;
		const url = `${ apiOptions.url }/${ propertyId }/goals/import`;
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider()
		          .post( url, { msProperty } )
		          .then( handleResponse );
	};

	const addGoalTerm = async ( property: Property, schoolYear: RelativeSchoolYear ): Promise<PropertyGoal[]> => {
		setIsLoading( true );
		const propertyId = property?.id ?? property;
		const url = `${ apiOptions.url }/${ propertyId }/goals/init`;
		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider()
		          .post( url, { schoolYear } )
		          .then( handleResponse );
	};
	//
	// const getStaffing = async (property: number|Property, config?: any):Promise<PropertyStaffing> => {
	// 	const id = (property as Property)?.id ?? property;
	// 	const url = `${apiOptions.url}/${id}/staffing`;
	// 	const api = await getApi( instance, accounts, apiOptions );
	//  return api.getProvider();
	// 	          .getAll(config)
	// 	          .then(handleResponse);
	// };

	return {
		isLoading,
		hasError,
		getProperties,
		getProperty,
		saveProperty,
		deleteProperty,
		restoreProperty,
		getContacts,
		saveContact,
		removeContact,
		updateVendors,
		removeVendor,
		getProjects,
		getCompetitors,
		saveCompetitor,
		getPropertyGoals,
		savePropertyGoals,
		importPropertyGoals,
		addGoalTerm,
		// getStaffing,

		generic: {
			typeSingle: 'Property',
			typePlural: 'Properties',
			createSchema: CreatePropertySchema,
			updateSchema: UpdatePropertySchema,
			isLoading,
			hasError,
			getAll: getProperties,
			getOne: getProperty,
			save: saveProperty,
			remove: deleteProperty,
			restore: restoreProperty,
			copy: undefined
		}
	};
};
