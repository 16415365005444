import { InteractionStatus } from '@azure/msal-browser';
import {
	useIsAuthenticated,
	useMsal
} from '@azure/msal-react';
import { unpackRules } from '@casl/ability/extra';
import React, {
	useEffect,
	useState
} from 'react';
import { useIntl } from 'react-intl';
// @ts-ignore

import {
	useSnackbar,
	VariantType
} from 'notistack';
import { updateAbilityFromRoles } from '../../config/AuthorizedRoute';
import { useUserApi } from '../../services/user-api';
import { getTokenRequest } from '../../utils/authConfig';

import PageTitle from '../hooks/PageTitle';
import GlobalSearch from '../GlobalSearch';
import ErrorDisplay from '../ErrorDisplay';
import { useAdminApi } from '../../services/admin-api';
import ClearIcon from '@mui/icons-material/Clear';
import Toolbar from '@mui/material/Toolbar';
import {
	IconButton,
	Tooltip,
	Link
} from '@mui/material';
import RolePicker from './RolePicker';

import { useAbility } from '@casl/react';
import { AbilityContext } from '../AbilityContext';


const AdminToolbar = () => {

	const handleError = ErrorDisplay();
	const {
		flushRedis,
	} = useAdminApi( handleError );
	const isAuthenticated = useIsAuthenticated();
	const { accounts, inProgress } = useMsal();
	const [roles, setRoles]=useState<string[]>()
	const ability = useAbility(AbilityContext);
	const api = useUserApi();
	const { enqueueSnackbar } = useSnackbar();
	useEffect(()=>{
		if ( isAuthenticated && inProgress === InteractionStatus.None ) {
			console.info( `AuthorizedRoute`, { accounts } );
			const { roles } = accounts[0]?.idTokenClaims ?? {};
			setRoles(roles)
		}
	})

	PageTitle( { id: 'admin', defaultTitle: 'Admin' } );
	const doFlushRedis = async () => {
		const data = await flushRedis( {} );
		let { result, keys } = data;
		if ( result ) {
			console.dir(result);
			console.dir(keys);
			const variant = result?.errors?.length ? 'warning' : 'info';
			enqueueSnackbar( `Redis Flush Done ${ keys } removed `, {
				variant,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center'
				}
			} );
		}
	};
	const doSetRoles = async ( roles: string[] ) => {
		console.info('doSetRoles:', {roles})
		if (roles && roles.length > 0) {
			updateAbilityFromRoles(roles, ability, api)
			   .then( ( permissions ) => {
				   const variant: VariantType = permissions?.length ? 'info' : 'warning';
				   console.info('doSetRoles:', { permissions })
				   enqueueSnackbar( <div>
					   <h3>
						   Role set to:
						   <ul>
							   { roles?.map((role:string)=><li>{ role }</li> ) }
						   </ul>
					   </h3>
					   <h3>Sign out to reset</h3>
				   </div>, {
					   variant,
					   anchorOrigin: {
						   vertical: 'top',
						   horizontal: 'center'
					   }
				   } );
			   } );
		}
	};

	return (
		<Toolbar disableGutters>
			<GlobalSearch/>
			<IconButton
				size="large"
				aria-label="Flush Redis"
				color="inherit"
				disabled={ !ability.can('execute', 'Task')}
				onClick={ doFlushRedis }>
				<Tooltip title="Flush Redis">
					<ClearIcon/>
				</Tooltip>
			</IconButton>
			<RolePicker apply={doSetRoles} currentRoles={roles ?? []} disabled={ !ability.can('update', 'IAM')}
			/>
		</Toolbar>
	);
};
export default AdminToolbar;
