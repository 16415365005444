// @ts-ignore
import { useMsal } from '@azure/msal-react';
import { useState } from 'react';
import { getApi } from './common/api-utils';

const namespace = 'college-house';

export type CollegeHouseMarket = {
	city: string
	stateAbbr: string
	regionKey: string;
	regionAbbr: string;
	bedsPurposeBuilt: number

}
// plural and single may be used for message logic if needed in the ApiCore class.

const apiOptions = {
	getAll: true,
	getSingle: true,
	post: true,
	put: true,
	patch: true,
	delete: true,
	url: namespace,
};

export const useCollegeHouseApi = ( errorCallback: any ) => {

	const [ isLoading, setIsLoading ] = useState( false );
	const [ hasError, setHasError ] = useState( false );
	const { instance, accounts } = useMsal();

	const handleResponse = ( response: any ) => {
		console.dir( response );
		setIsLoading( false );
		//return response.data || initialData;
		return response.data;
	};

	const handleError = ( response: any ) => {
		setIsLoading( false );
		console.dir( response );
		const isError = response.status >= 400;
		setHasError( isError );
		if ( !isError ) {
			return;
		}

		// if api error, use that, otherwise, get response message
		let data;
		if ( typeof response.data === 'object' ) {
			data = response.data;
		}
		else {
			data = {
				code: response.status,
				message: response.statusText
			};
		}
		if ( typeof errorCallback === 'function' ) {
			return errorCallback( data );
		}
		throw data;
	};
	const getMarket = async ( key: number ): Promise<CollegeHouseMarket> => {
		setIsLoading( true );
		const url = `${ namespace }/market`;
		console.dir( url );

		const api = await getApi( instance, accounts, apiOptions );
		return api.getProvider()
		          .getOne( url, key )
		          .then( handleResponse )
		          .catch( handleError );
	};

	return {
		isLoading,
		hasError,
		getMarket


	};
};
