import { useIsAuthenticated } from '@azure/msal-react';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import Page from 'material-ui-shell/lib/containers/Page';
import React, { useEffect, useMemo, useState } from 'react';
import GlobalSearch from '../../components/GlobalSearch';
import CoreDataGrid from '../../components/grid/CoreDataGrid';
import useIntlPlus from '../../components/hooks/IntlPlus';
import OEAddDialog from '../../components/OperatingEntity/OEAddDialog';
import useOEGridColumns from '../../components/OperatingEntity/OEGridColumns';
import { useOperatingEntityApi } from '../../services/operating-entity-api';
import ErrorDisplay from '../../components/ErrorDisplay';

import { useSearchParams } from 'react-router-dom';


const OperatingEntities = () => {
	const intl = useIntlPlus();
	const isAuthenticated = useIsAuthenticated();
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const handleError = ErrorDisplay();
	const api = useOperatingEntityApi(handleError);
	const [searchParams] = useSearchParams();
	const columns = useOEGridColumns();
	const gridApiRef = useGridApiRef();
	const fetchData = useMemo(()=>(params, config)=>{
		api.getAll({params: { ...params, scope: params.get('scope') }, ...config})
			.then((data) => {
				setData(data || []);
			});
	},[api]);

	useEffect(()=>{
		if (isAuthenticated) {
			fetchData(searchParams, {});
		}
	}, [isAuthenticated, searchParams]);

	useEffect(() => {
		if (refresh) {
			setRefresh(false);
			fetchData(searchParams,{ headers: { 'cache-control':'max-age=0' } }); //force cache refresh
		}
	}, [refresh, api, searchParams, fetchData]);
	return (
		<Page
			pageTitle={ intl.formatMessage({ id: api.typePlural?.toLowerCase() }) }
			isLoading={ api.isLoading }
			appBarContent={
				<GlobalSearch/>
			}
		>
			<CoreDataGrid
				api = { api }
				gridApiRef={ gridApiRef }
				data = { data }
				setRefresh={ setRefresh }
				columns={ columns }
				AddDialog={ OEAddDialog }
				disableGridViewEditing={true}
				disableGridViewSelector={true}
				defaultGridSettings={{
					editable: true
				}}
			/>
		</Page>
	);
};
export default OperatingEntities;
